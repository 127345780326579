// import $ from 'jquery'
import axios from "axios";


// import moment from 'moment'

export default {
    components: {

    },
    data: function() {
        return {
            pageTitle: "Tableau de Bord",
            dateOptions: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            filtre:{
                date_debut:'',
                date_fin:'',
            },
            data:[],
        }
    },

    methods: {
        getData(){
            let formulaire = new FormData();
            formulaire.append('date_debut',this.filtre.date_debut);
            formulaire.append('date_fin',this.filtre.date_fin);

            axios.post(this.BASE_URL + '/dossier/dashboard',formulaire).then((response) => {
                this.data = response.data;
            })
        },
        setFilter() {
            this.getData();
        },
        resetFilter() {
            this.filtre={
                date_debut: '',
                date_fin: ''
            }
            this.setFilter();
        },


    },

    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";

    },
    mounted: function() {
        this.getData();
    }
}
